import React from 'react'
import './home.css';
import Header from "./components/Header";
import Apps from "./components/Apps";


export default function Home() {
   

    return (
        <div className="container">
            <Header/>
            <Apps/>
        </div>
    );
}
