import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en.json";
import es from "./es.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // lng: "es",
    // fallbackLng: "es",
    debug: false,
    resources: {
      en: en,
      es: es,
    },
  })
  .finally(() => {});

export default i18n;
