import React from 'react'
import '../home.css';
import {useTranslation} from "react-i18next";
import logo from '../../../assets/logo.webp'

export default function Header() {
    const {t} = useTranslation("home");
  
    return (
        <>
            <img src={logo} className="logo" alt="logo"/>
            <div className={'space-50'}/>
        </>
    );
}

