import React from 'react';
import ReactDOM from 'react-dom/client';
import "../src/util/translations/i18n";
import App from './app'
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<BrowserRouter> <App/> </BrowserRouter>);

reportWebVitals();
