import React from "react";
import "../home.css";
import appstore from "../../../assets/appstore.png";
import googleplay from "../../../assets/googleplay.png";
import { Box, Stack } from "@mui/material";

export default function Apps() {
  React.useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return window.location.replace("https://play.google.com/store/apps/details?id=com.softimatic.beforeboarding");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return window.location.replace("https://apps.apple.com/us/app/beforeboarding/id1515591477");
     
    }

    return "unknown";
  }

  const appStore = (os) => {
    if (os === "ios") {
      window.location.replace(
        "https://apps.apple.com/us/app/beforeboarding/id1515591477"
      );
    } else {
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.softimatic.beforeboarding"
      );
    }
  };

  return (
    <>
      <Stack spacing={5} flexDirection={'column'} alignItems={'center'}>
        <span className={"title"}>Before Boarding App</span>
    
        <Box onClick={() => appStore("ios")} sx={{ cursor: "pointer" }}>
          <img src={appstore} className="logo-app" alt="logo" />
        </Box>

        <div className={"space-25"} />

        <Box onClick={() => appStore("android")} sx={{ cursor: "pointer" }}>
          <img src={googleplay} className="logo-app" alt="logo" />
        </Box>
      </Stack>
    </>
  );
}
