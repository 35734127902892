import Footer from "./screens/footer";
import React from "react";
import Home from "./screens/home";

export default function App() {

    return <>
        <Home/>
        <Footer/>
    </>
}
