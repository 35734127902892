import React from 'react'
import './footer.css';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";


const path = [
    {name:'about us', url:"https://beforeboarding.com/sobre-nosotros/"},
    {name:'contact', url:"https://beforeboarding.com/contacto/"},
    {name:'terms and conditions', url:"https://beforeboarding.com/terminos-y-condiciones-de-before-boarding/"},
    {name:'legal notice', url:"https://beforeboarding.com/aviso-legal/"},
    {name:'cookie policy', url:"https://beforeboarding.com/politicas-de-cookies/"},
    {name:'press room', url:"https://beforeboarding.com/sala-de-prensa/"},
]

export default function Footer() {

    const {t} = useTranslation("home");

    return (
        <div className={'footer'}>

            <Grid container className={'footer-container'}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{textAlign: 'center', marginBottom:30}}>
                                <img src={require('../../assets/logoshort.png')} className="logo" alt="logo"/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Grid container spacing={2} className={'footer-column'}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <span>
                                <b>SANTO DOMINGO - AEROPUERTO LAS AMÉRICAS</b>
                                <br/>
                                info.sdq@beforeboarding.com
                                <br/> Tel: +1 809 549 02 02
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <span>
                                <b>PUERTO PLATA - AEROPUERTO GREGORIO LUPERÓN</b>
                                <br/>
                                info.sdq@beforeboarding.com
                                <br/> Tel: +1 809 549 02 02
                            </span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Grid container spacing={2} className={'footer-column'}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <span>
                                <b>SAMANÁ - AEROPUERTO JUAN BOSCH EL CAREY</b>
                                <br/>
                                info.sdq@beforeboarding.com
                                <br/> Tel: +1 809 549 02 02
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <span>
                                <b>SANTIAGO DE LOS CABALLEROS - AEROPUERTO DEL CIBAO</b>
                                <br/>
                                info.sdq@beforeboarding.com
                                <br/> Tel: +1 809 549 02 02
                            </span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <Grid container spacing={2} className={'footer-column'}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <span>
                                <b>Before Boarding App </b>
                                <br/>
                                <div className={'space'}/>
                                <img onClick={()=>window.open('https://play.google.com/store/apps/details?id=com.softimatic.beforeboarding', '_blank')} style={{cursor:'pointer'}} src={require('../../assets/googleplay.png')} className="footer-img-icon" alt="logo"/>
                                <img onClick={()=>window.open('https://apps.apple.com/us/app/beforeboarding/id1515591477', '_blank')} style={{cursor:'pointer'}} src={require('../../assets/appstore.png')} className="footer-img-icon" alt="logo"/>
                            </span>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <img src={require('../../assets/Asset-2@33.33x-1.png')} className={'footer-cc'}
                                 alt="logo"/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <div className={'footer-path'}>
                <div className={'footer-path2'}>
                    <Grid container  direction="row" justifyContent="space-around"  alignItems="center" className={'footer-column'}>
                        {path.map(p => {
                            return (<Grid onClick={()=>window.open(p.url)} sx={{cursor:'pointer'}} key={p.name} item xs={12} sm={12} md={2} lg={2}>
                                    <h4 style={{color:'#bdbdbd'}}>{t(p.name)}</h4>
                            </Grid>)
                        })}
                    </Grid>
                </div>
            </div>
            <div className={'footer-path'} style={{backgroundColor:'#222222'}}>
                <div className={'footer-path2'}>
                    <Grid container  direction="row" justifyContent="space-around"  alignItems="center">
                        <Grid  item xs={12}>
                            <h5 style={{color:'#bdbdbd'}}>{t('All rights reserved')}</h5>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
